<template>
  <div class="about">
    <h1>This{{data}} is an about pa222222222ge</h1>
  </div>
</template>
<script>
export default{
	data(){
		return{
			data:22
		}
	}
}
</script>
